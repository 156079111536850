import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box, Grid } from "@mui/material";
import {
  DateField,
  NumberField,
  ReferenceOneField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import { FieldGrid } from "../../display/FieldGrid";
import { FieldItem } from "../../display/FieldItem";
import { PipedriveLink } from "../../fields/PipedriveLink";
import { PageTitle } from "../../layout/PageTitle";
import { ClientIcon } from "../organization";
import { DealDeploymentTab } from "./tabs/DealDeploymentTab";
import { DealProjectsTab } from "./tabs/DealProjectsTab";

export const DealShow = () => {
  return (
    <Show>
      <PageTitle />
      <Box margin={2}>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item>
            <TextField source="name" variant="h4" />
          </Grid>
          <Grid item>
            <PipedriveLink resource="deal" source="pipedriveId" />
          </Grid>
        </Grid>

        <FieldGrid>
          <FieldItem label="Name" icon={<BusinessCenterIcon />}>
            <TextField source="name" />
          </FieldItem>

          <FieldItem label="Won date" icon={<CalendarMonthIcon />}>
            <DateField source="wonDate" />
          </FieldItem>

          <FieldItem label="Quoted device count" icon={<BusinessCenterIcon />}>
            <NumberField source="quotedDeviceCount" />
          </FieldItem>

          <FieldItem label="Client" icon={<ClientIcon />}>
            <ReferenceOneField
              reference="organizations"
              target="id"
              source="organizationId"
            >
              <TextField source="name" />
            </ReferenceOneField>
          </FieldItem>
        </FieldGrid>

        <TabbedShowLayout>
          <Tab label="Deployment">
            <DealDeploymentTab />
          </Tab>
          <Tab label="Projects">
            <DealProjectsTab />
          </Tab>
        </TabbedShowLayout>
      </Box>
    </Show>
  );
};
