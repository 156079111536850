import { blueGrey, green, purple, red } from "@mui/material/colors";
import { EnumField } from "../../../fields/EnumField";

export const validationStatusLabels = {
  unknown: "Unknown",
  deleted: "Deleted",
  "backOffice:givenByUser": "Back Office: Given by user",
  "backOffice:otherReliable": "Back Office: Other reliable",
  "backOffice:otherUnreliable": "Back Office: Other unreliable",
  "residentApp:unverified": "Resident App: Not verified",
  "intervention:inReport": "Intervention: In Report",
  "brevo:delivered": "Brevo: Delivered",
  "brevo:uniqueOpened": "Brevo: Unique Opened",
  "brevo:click": "Brevo: Click",
  "brevo:hardBounce": "Brevo: Hard Bounce",
  "brevo:invalidEmail": "Brevo: Invalid Email",
  "brevo:error": "Brevo: Error",
  "brevo:spam": "Brevo: Spam",
  "brevo:unsubscribed": "Brevo: Unsubscribed",
  "brevo:blocked": "Brevo: Blocked",
};

export const ContactValidationStatusField = ({ label }: { label?: string }) => (
  <EnumField
    defaultColor={blueGrey[300]}
    colors={{
      deleted: red[500],
      "backOffice:givenByUser": green[500],
      "backOffice:otherReliable": green[500],
      "backOffice:otherUnreliable": blueGrey[500],
      "residentApp:unverified": blueGrey[500],
      "brevo:delivered": green[500],
      "brevo:uniqueOpened": green[500],
      "brevo:click": green[500],
      "brevo:hardBounce": purple[500],
      "brevo:invalidEmail": purple[500],
      "brevo:error": purple[500],
      "brevo:spam": purple[500],
      "brevo:unsubscribed": purple[500],
      "brevo:blocked": purple[500],
    }}
    labels={validationStatusLabels}
    label={label || "Validation Status"}
    source="status"
  />
);
